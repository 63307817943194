// extracted by mini-css-extract-plugin
export var maineDigest = "maine_digest_2021_09-module--maineDigest--+FRmV";
export var heroImage = "maine_digest_2021_09-module--heroImage--5UAeJ";
export var columnWrapper = "maine_digest_2021_09-module--columnWrapper--ImmqC";
export var headingLogo = "maine_digest_2021_09-module--headingLogo--AgMnR";
export var headingWrapper = "maine_digest_2021_09-module--headingWrapper--mzaCi";
export var heading = "maine_digest_2021_09-module--heading--vogLA";
export var instance = "maine_digest_2021_09-module--instance--7m15n";
export var subheading = "maine_digest_2021_09-module--subheading--BRZZ7";
export var textWrapper = "maine_digest_2021_09-module--textWrapper--nsBbJ";
export var latestnewsarticleheadline = "maine_digest_2021_09-module--latestnewsarticleheadline--++QbX";
export var dateline = "maine_digest_2021_09-module--dateline--h04F6";
export var whatsNew = "maine_digest_2021_09-module--whatsNew--nVe6W";
export var bioWrapper = "maine_digest_2021_09-module--bioWrapper--LVAgs";
export var leftBio = "maine_digest_2021_09-module--leftBio--kvffF";
export var rightBio = "maine_digest_2021_09-module--rightBio--B2Pou";
export var photoCaption = "maine_digest_2021_09-module--photoCaption--i6AK2";
export var afterCaption = "maine_digest_2021_09-module--afterCaption--IzmS2";